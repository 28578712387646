import { all, takeEvery, fork, put, select } from 'redux-saga/effects';
import { history } from '@launchpad';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* authLoaded(action) {
  try {
    const state = yield select();

    if (state.paymentDevices.length > 0) {
      let paymentDevice = state.paymentDevices[0];
      if (!paymentDevice.isLive) {
        history.push('/card');
      }
    }
  } catch (e) {}
}

/**
 * Called on logout done
 *
 * @param action object
 */
function* logoutDone(action: any) {
  window.location.href =
    process.env.REACT_APP_LOGIN_URL || 'https://member.prosper2.co.uk/';
  // window.location.href = process.env.REACT_APP_LOGIN_URL || 'https://pactuat.azurewebsites.net/'; // STAGE URL
}

/**
 * Watch for member auth redirections
 */
export function* watchMemberAuthRedirection() {
  yield takeEvery('MEMBER_AUTH_REDIRECTED', authLoaded);
}

/**
 * Watch for logout
 */
export function* watchLogout() {
  yield takeEvery('MEMBER_LOGOUT_DONE', logoutDone);
}

/**
 * Export sagas
 */
export default function* authSagas() {
  yield fork(watchMemberAuthRedirection);
  yield fork(watchLogout);
}
