import React from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { AppIcon, OverrideService } from '@launchpad';
import CardService from '@launchpad/modules/card/CardService';
import { connect } from 'react-redux';
import i18n from '@launchpad/i18n';

class RotateCard extends React.Component<any, any> {
  flippy: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isFlipped: false
    };
  }

  customFlipOnClick() {
    let paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    if (this.props.paymentDevices.length > 1) {
      paymentDevice = this.props.card;
    }
    const cardActive = paymentDevice.isLive;

    if (cardActive && paymentDevice.status !== 'locked') {
      this.setState({
        isFlipped: !this.state.isFlipped
      });
    }
  }

  render() {
    let paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    if (this.props.paymentDevices.length > 1) {
      paymentDevice = this.props.card;
    }

    if (!paymentDevice) return null;

    const cardActive = paymentDevice.isLive;
    const { frontCard, backCard } = this.props || OverrideService.getStyles();

    return (
      <div className="flip-card-holder">
        <div
          className="send-load-btn-wrapper rotate-btn"
          style={{
            opacity: cardActive && paymentDevice.status !== 'locked' ? 1 : 0.3
          }}
        >
          <div
            className="send-load-icon-wrapper"
            onClick={() => this.customFlipOnClick()}
          >
            <AppIcon name="fa.refresh" style={{ fontSize: 24 }} />
          </div>
        </div>
        <Flippy
          flipOnHover={false} // default false
          flipOnClick // default false
          flipDirection="horizontal" // horizontal or vertical
          ref={(r: any) => (this.flippy = r)} // to use toggle method like this.flippy.toggle()
          isFlipped={this.state.isFlipped}
          // if you pass isFlipped prop component will be controlled component.
        >
          <FrontSide onClick={() => this.customFlipOnClick()}>
            <img
              src={frontCard || require('../../assets/card.png')}
              alt="card"
              className="card-page-card-image"
            />
          </FrontSide>
          <BackSide onClick={() => this.customFlipOnClick()}>
            <p className="card-pan">{i18n.t(paymentDevice.pan)}</p>
            <p className="card-expires">{i18n.t(paymentDevice.expires)}</p>
            <img
              src={backCard || require('../../assets/card.png')}
              alt="card"
              className="card-page-card-image"
            />
          </BackSide>
        </Flippy>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};
export default connect(mapStateToProps)(RotateCard);
