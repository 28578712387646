import React from 'react';
import { connect } from 'react-redux';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppIcon
} from '@launchpad/index';
import ToastHelper from '@launchpad/util/ToastHelper';
import { Trans } from 'react-i18next';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import ChangePasswordFormModel from '../models/ChangePasswordFormModel';
import i18n from '@launchpad/i18n';

export const NAME = 'CHANGE_PASSWORD';

class ChangePasswordModal extends React.Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ChangePasswordFormModel({
        password: '',
        repeatedPassword: ''
      }),
      {
        show: true
      }
    );
  }

  onClose() {
    ModalService.hideModal('CHANGE_PASSWORD');
  }

  onSubmitSuccess(data: any) {
    if (data.success) {
      ToastHelper.show(
        'You have successfully changed the password.',
        'success'
      );
      ModalService.hideModal('CHANGE_PASSWORD');
    }
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    const user = this.props.profile;
    const { formHandler } = this;

    if (!user) return null;

    return (
      <ModalDialog show={this.state.show} onClose={this.onClose.bind(this)}>
        <div
          className="transaction-modal"
          style={{
            minHeight: 450,
            height: 'auto',
            maxHeight: '100%',
            justifyContent: 'flex-start'
          }}
        >
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <div
            className="inner-container"
            style={{ justifyContent: 'space-around', width: '100%' }}
          >
            <h1 className="pt-3 pb-5">
              <Trans>CHANGE PASSWORD</Trans>
            </h1>
            {user.oneTimePasswordUsed && (
              <p className="pb-5">
                <Trans>
                  We detected that you used randomly generated password by our
                  system. We advise you to change it to something more
                  rememberable. <br /> You can do this later in the PROFILE
                  section.
                </Trans>
              </p>
            )}
            <div className="change-password-form-wraper">
              <FormSubmitWrapper
                formHandler={formHandler}
                buttonClass="gold-button"
                to="/"
                buttonText="CHANGE PASSWORD"
                getModalMessage={(data: { success: any; message: any }) =>
                  this._getModalMessage(data)
                }
              >
                {/* <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label="Old Password"
                    type="password"
                    name="oldPassword"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.oldPassword}
                    handler={formHandler}
                  />
                </div> */}
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('New Password')}
                    type="password"
                    name="password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.password || ''}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper mb-5">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('Confirm Password')}
                    type="password"
                    name="repeatedPassword"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.repeatedPassword || ''}
                    handler={formHandler}
                  />
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(ChangePasswordModal);
