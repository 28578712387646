import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppToLoad from './variants/@samsung';

const favicon: any = document.querySelector('link[rel="icon"]');

favicon.setAttribute('href', 'samsung-favicon.ico');

document.title = 'SAMSUNG Rewards';

ReactDOM.render(<AppToLoad />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
