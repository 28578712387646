import { OverrideService } from '@launchpad';
import React from 'react';
import { Trans } from 'react-i18next';

const SamsungFooter = () => {
  return (
    <div className="row">
      <div className="col-lg-4 footer-column">
        {/* <h3>LEGAL</h3>
          <a href="https://www.prosper2.co.uk/websiteterms" target="_blank" className='footer-link'>Website T&Cs</a>
          <a href="https://www.prosper2.co.uk/terms" target="_blank" className='footer-link'>Rewards Programme T&Cs</a>
          <a href="https://www.prosper2.co.uk/memberterms" target="_blank" className='footer-link'>Membership T&Cs</a>
          <a href="https://www.prosper2.co.uk/cardholder-terms-conditions" target="_blank" className='footer-link'>Cardholder T&Cs</a> */}
        <h3>CONTACT</h3>
        <a
          href="mailto:cardservices@partnerrewards.co.uk"
          className="footer-link"
        >
          Email: cardservices@partnerrewards.co.uk
        </a>
        <a href="tel:0203 925 2759" className="footer-link">
          Card Services: 0203 925 2759
        </a>
      </div>
      <div className="col-lg-4 footer-column">
        <h3>INFORMATION</h3>
        <a
          href="https://prosper2.co.uk/cardholder-terms-and-conditions-tpl"
          target="_blank"
          className="footer-link"
        >
          Terms and Conditions
        </a>
      </div>
      <div className="col-lg-4 footer-column last-column">
        {/* <h3>CONNECT WITH US</h3>
          <div>
            <a target='_blank' href="https://www.facebook.com/prosper2uk/"><AppIcon name={'fa.facebook-square'} style={{ fontSize: 40, color: 'white' }} /></a>
            <a target='_blank' href="https://twitter.com/Prosper2uk"><AppIcon name={'fa.twitter'} style={{ fontSize: 40, color: 'white' }} /></a>
            <a target='_blank' href="https://www.linkedin.com/company/prosper²"><AppIcon name={'fa.linkedin-square'} style={{ fontSize: 40, color: 'white' }} /></a>
          </div> */}
        <div className="pannovate-logo">
          <p>powered by</p>
          <img
            src={require('@launchpad/assets/pannovate_logo_black.svg')}
            alt="pannovate-logo"
          />
        </div>
      </div>

      <div className="last-line">
        <p>
          © {OverrideService.getStyles().APP_NAME} {new Date().getFullYear()}{' '}
          <Trans>copyright</Trans>
        </p>
      </div>
    </div>
  );
};

export default SamsungFooter;
