/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import SamsungFooter from './components/SamsungFooter';

const overrides = {
  card: {
    image: () => {
      return (
        <img
          src={require('../../assets/samsung_card_front.png')}
          className="card-page-card-image"
          alt="card"
        />
      );
    },
    page: {
      linkTC: () => (
        <a
          href="https://prosper2.co.uk/cardholder-terms-and-conditions-tpl"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          Terms & Conditions
        </a>
      ),
      lockModalNumber: () => (
        <a href="tel:0203 925 2759" className="report-number">
          <strong>(0203 925 2759)</strong>
        </a>
      )
    }
  },
  footer: {
    content: () => <SamsungFooter />
  },
  'unread-circle-color': '#000000',
  'hide-theme-switcher': true,
  'hide-language-switcher': true
};

export default overrides;
