import colors from '../../styles/_variables.scss';

const styles = {
  colors,

  APP_NAME: 'Samsung Rewards',

  // ASSETS
  logo: require('../../assets/Samsung-Rewards_logo.svg'),
  frontCard: require('../../assets/samsung_card_front.png'),
  backCard: require('../../../../@prosper/assets/img/Prosper-Card-Back.png'),
  activateCardImage: require('../../../../@prosper/assets/img/activate_card.png')
};

export default { ...styles };
