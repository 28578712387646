import overrides from './overrides';
import config from './config';
import styles from './styles';

const configuration = {
  overrides,
  config,
  styles
};

export default configuration;
